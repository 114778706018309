.App {
  text-align: left;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #ffffff;
  min-height: 30vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #0c0552;
}

.App-body {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(2px + 2vmin);
  color: #0c0552;
}

.Name {
  font-size: calc(20px + 2vmin);
  margin: 5px;
}

.Designation {
  font-size: calc(10px + 1vmin);
  margin: 5px;
}

.SocialLinks {
  float: left;
  padding: 1em;
}

.SocialLink {
  padding: 1em;
}

.App-link {
  color: #61dafb;
}

img {
  width: 23px;
  height: 23px;
}

.Microsoft {
  color: #00171d;
  text-decoration-line: none;
  font-weight: bold;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
